
































































































import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class InsCatLayout1 extends Vue {
  @Prop({ default: () => [] }) private catData!: object[]; // cms目录数据
  @Prop({ default: () => [] }) private cmsData!: object[]; // cms内容数据
  @Prop({ default: () => [] }) private catclass!: object[];

  // 树形控件配置选项
  defaultProps: object = {
    children: 'Children',
    label: 'Name'
  };

  handleNodeClick (data) {
    console.log(data, 'handleNodeClick');
    this.$emit('changeCatSelect', data.Id);
  }

  get isMobile () {
    return this.$store.state.isMobile;
  }
}
