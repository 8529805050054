import { render, staticRenderFns } from "./InsCatLayout1.vue?vue&type=template&id=1f98b6ac&scoped=true&"
import script from "./InsCatLayout1.vue?vue&type=script&lang=ts&"
export * from "./InsCatLayout1.vue?vue&type=script&lang=ts&"
import style0 from "./InsCatLayout1.vue?vue&type=style&index=0&lang=less&"
import style1 from "./InsCatLayout1.vue?vue&type=style&index=1&id=1f98b6ac&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f98b6ac",
  null
  
)

export default component.exports